import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import HowItWorksPage from '../ui/pages/HowItWorksPage'

const HowItWorks = props => (
  <>
    <HelmetComponent
      title={metaTags.howItworksTitle}
      description={metaTags.howItWorks}
      page="how-it-works"
    />
    <AppWrapper {...props}>
      <HowItWorksPage />
    </AppWrapper>
  </>
)

export default HowItWorks
